<template>
	<section>
		<b-row class="mb-2">
			<b-col>
				<Breadcrumb :labels="labels" />
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div class="relative">
					<b-tabs card class="caption-add table-custom">
						<b-tab title="Basic Info" active class="overview-tab">
							<validation-observer ref="form_rel" #default="{ invalid }">
								<b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
									<b-row class="align-center">
										<b-col lg="6">
										</b-col>
										<template v-if="(userData && permission.create)">
											<b-col lg-6>
												<div class="text-right mb-2">
													<b-button type="button" variant="basic" class="btn-df size-18 black"
														@click="cancelAdd"
														style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

													<b-button type="button" variant="primary" class="btn-df size-18 black"
														@click="saveAdd">
														Save
													</b-button>
												</div>
											</b-col>
										</template>
										<template v-else>
											<b-col lg-6>
												<div class="text-right mb-2">
													<b-button type="button" variant="basic" class="btn-df size-18 black"
														@click="cancelAdd"
														style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

													<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray"
														disabled>
														Save
													</b-button>
												</div>
											</b-col>
										</template>
									</b-row>
									<div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
									<div class="frm-basic size-16 black-child d-block">
										<b-row style="width: 100%; margin: 0;">
											<b-col lg="12" class="px-0">
												<b-form-group label="Organisation Name" label-cols-lg="2"
													class="star-required input-group-label">
													<validation-provider #default="{ errors }" name="Organisation Name"
														rules="required">
														<b-form-input v-model="fieldOr.name" placeholder="Name"
															:formatter="length100"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="Abbreviation" label-cols-lg="2"
													class="star-required input-group-label">
													<validation-provider #default="{ errors }" name="Abbreviation"
														rules="required">
														<b-form-input v-model="fieldOr.abbreviation" placeholder="ORG1"
															:formatter="length50"></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Country" label-cols-lg="4" class="star-required">
													<validation-provider #default="{ errors }" name="Country"
														rules="required">
														<v-select v-model="selectedCountry" label="name"
															:options="selectCountry" placeholder="Select Country" />
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0">
												<b-form-group label="Status" label-cols-lg="4" class="star-required">
													<validation-provider #default="{ errors }" name="Status"
														rules="required">
														<v-select v-model="selectedStatus" label="title"
															:options="selectStatus" placeholder="Select Status" />
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
											</b-col>
											<b-col lg="12" class="px-0">
												<b-form-group label="Description" label-cols-lg="2" class="star-required">
													<validation-provider #default="{ errors }" name="Description"
														rules="required">
														<b-form-textarea v-model="fieldOr.description"
															placeholder="Description" rows="4"
															:formatter="length1000"></b-form-textarea>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-form-group>
												<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
											</b-col>
											<b-col lg="6" class="px-0 cb-aligncenter pl-10t">
												<b-form-group label="Is Client" label-cols-lg="4">
													<b-form-radio-group v-model="selectedClient" :options="optionsClient">
													</b-form-radio-group>
												</b-form-group>
											</b-col>
											<b-col lg="6" class="px-0 cb-aligncenter pl-10t">
												<b-form-group label="Is Partner" label-cols-lg="4">
													<b-form-radio-group v-model="selectedPartner" :options="optionsPartner">
													</b-form-radio-group>
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</b-form>
							</validation-observer>
						</b-tab>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

import Breadcrumb from '../partial/Breadcrumb';

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../model/globalAdmin';

export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			searchQuery: null,
			labels: { "itees-organisations-add": "" },

			fieldOr: {
				name: null,
				abbreviation: null,
				description: null,
			},
			selectedStatus: null,
			selectStatus: [
				{ title: 'Inactive', value: 0 },
				{ title: 'Active', value: 1 },
			],
			permission: [],
			selectedCountry: null,
			selectCountry: [],
			selectedClient: '1',
			optionsClient: [
				{ text: 'Yes', value: '1' },
				{ text: 'No', value: '0' },
			],
			selectedPartner: '1',
			optionsPartner: [
				{ text: 'Yes', value: '1' },
				{ text: 'No', value: '0' },
			],
		}
	},
	created() {
		this.permission = this.checkPermission(this.userData, "Organisation");
		this.listAllCountry()
	},
	methods: {
		length1000(e) {
			return String(e).substring(0, 1000)
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		length50(e) {
			return String(e).substring(0, 50)
		},
		listAllCountry() {
			this.$store
				.dispatch('auth/fetchCountry')
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		cancelAdd() {
			this.$router.push({ name: 'itees-organisations' })
		},
		saveAdd() {
			this.$refs.form_rel.validate().then(success => {
				if (success) {
					let datas_or = {
						name: this.fieldOr.name,
						abbreviation: this.fieldOr.abbreviation,
						is_client: this.selectedClient ? this.selectedClient : null,
						is_partner: this.selectedPartner ? this.selectedPartner : null,
						country_id: this.selectedCountry ? this.selectedCountry.id : null,
						description: this.fieldOr.description,
						status: this.selectedStatus ? this.selectedStatus.value : null,
					}
					// console.log('datas_or: ', datas_or)
					this.$store
						.dispatch('auth/addOr', datas_or)
						.then(response => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: this.fieldOr.name + ' Added!',
									variant: 'success',
								},
							},
								{
									position: 'top-center'
								})

							this.$router.push({ name: 'itees-organisations' })

						})
						.catch((error) => {
							console.log(error)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response.data.message,
									variant: 'danger',
								},
							},
								{
									position: 'top-center'
								})
						})
				}
				else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'One or more fields have an error. Please check and try again.',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				}
			})
		}
	},
};

</script>

<style lang="scss">
@import "@/assets/scss/_ite_tab_table.scss";

.tab-pt-0 .px-table-0>.card-body {
	padding-top: 0;
}

.Vue-Toastification__container.top-center {
	top: 20px;
}
</style>