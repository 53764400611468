var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('Breadcrumb',{attrs:{"labels":_vm.labels}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"relative"},[_c('b-tabs',{staticClass:"caption-add table-custom",attrs:{"card":""}},[_c('b-tab',{staticClass:"overview-tab",attrs:{"title":"Basic Info","active":""}},[_c('validation-observer',{ref:"form_rel",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"profile-frm mb-20",staticStyle:{"border-radius":"5px"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-center"},[_c('b-col',{attrs:{"lg":"6"}}),((_vm.userData && _vm.permission.create))?[_c('b-col',{attrs:{"lg-6":""}},[_c('div',{staticClass:"text-right mb-2"},[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelAdd}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.saveAdd}},[_vm._v(" Save ")])],1)])]:[_c('b-col',{attrs:{"lg-6":""}},[_c('div',{staticClass:"text-right mb-2"},[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelAdd}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Save ")])],1)])]],2),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Organisation Name","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Organisation Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Name","formatter":_vm.length100},model:{value:(_vm.fieldOr.name),callback:function ($$v) {_vm.$set(_vm.fieldOr, "name", $$v)},expression:"fieldOr.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Abbreviation","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Abbreviation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"ORG1","formatter":_vm.length50},model:{value:(_vm.fieldOr.abbreviation),callback:function ($$v) {_vm.$set(_vm.fieldOr, "abbreviation", $$v)},expression:"fieldOr.abbreviation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Country","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectCountry,"placeholder":"Select Country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Status","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.selectStatus,"placeholder":"Select Status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Description","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"4","formatter":_vm.length1000},model:{value:(_vm.fieldOr.description),callback:function ($$v) {_vm.$set(_vm.fieldOr, "description", $$v)},expression:"fieldOr.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"border-top":"1px solid #B1B1B1","height":"14px"}})],1),_c('b-col',{staticClass:"px-0 cb-aligncenter pl-10t",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Is Client","label-cols-lg":"4"}},[_c('b-form-radio-group',{attrs:{"options":_vm.optionsClient},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)],1),_c('b-col',{staticClass:"px-0 cb-aligncenter pl-10t",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Is Partner","label-cols-lg":"4"}},[_c('b-form-radio-group',{attrs:{"options":_vm.optionsPartner},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }